import { gql } from "@apollo/client";

// 분류체계
export const FRAGMENT_CLASSIFICATION = gql`
  fragment ClassificationChildrenFields on ClassificationResponse {
    year
    id
    name
    type
    depth
    companyGroup {
      id
      name
      __typename
    }
    parent {
      year
      id
      name
      type
      depth
      companyGroup {
        id
        name
        __typename
      }
      __typename
    }
    contents {
      language
      content
      order
      __typename
    }
    kclubDiagnosisList {
      type
      code
      abilityCode
      __typename
    }
    __typename
  }

  fragment ClassificationChildrenRecursive on ClassificationResponse {
    ...ClassificationChildrenFields
    children {
      ...ClassificationChildrenFields
      children {
        ...ClassificationChildrenFields
        children {
          ...ClassificationChildrenFields
          __typename
        }
        __typename
      }
      __typename
    }
    __typename
  }
`;
